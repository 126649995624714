import SvgAudiPlus from '@/assets/audi-gw-plus.svg';
import DasWeltAuto from '@/assets/weltauto.svg';
import SkodaPlus from '@/assets/skoda-gw-plus.png';

export const AUDI_QUALITY_BADGE = 'Audi Gebrauchtwagen:plus';
export const VW_QUALITY_BADGE = 'Volkswagen ’Das WeltAuto’';
export const SKODA_QUALITY_BADGE = 'Skoda Gebrauchtwagen:Plus';

/**
 * @param {string} qualityBadge
 * @return {import('vue').Component}
 */
export function fetchQualityBadge(qualityBadge, vendor) {
  const hideQualityBadge = vendor === 'Schröder Teams';
  if (qualityBadge) {
    switch (qualityBadge) {
      case AUDI_QUALITY_BADGE:
        if (hideQualityBadge) return;
        return SvgAudiPlus;
      case VW_QUALITY_BADGE:
        return DasWeltAuto;
      case SKODA_QUALITY_BADGE:
        return SkodaPlus;
    }
  }
}
