import store from '@/store';
import { noop } from 'lodash-es';

/**
 * @param {Array} dataLayer
 * @return {{ enqueue: (event: Object): void }}
 */
const tracking = function(dataLayer) {
  let _eventQueue = [];
  let _isDomReady = false;

  const isDomReady = () => {
    if (!_isDomReady) {
      _isDomReady = dataLayer.filter(({ event }) => event === 'gtm.dom').length > 0;
    }
    return _isDomReady;
  };

  const enqueue = event => {
    if (isDomReady()) {
      if (_eventQueue.length) {
        _eventQueue.forEach(e => dataLayer.push(e));
        _eventQueue = [];
      }
      dataLayer.push(event);
    } else {
      _eventQueue.push(event);
    }
  };

  return enqueue;
};

/**
 *
 * @param {String} gtmId
 * @param {String} dataLayerName
 */
const registerGTM = function(gtmId, dataLayerName, customerAnalyticsId) {
  return (function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });
    w[l].push({
      customerAnalyticsId
    });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
    return {
      dataLayer: w[l],
      element: j
    };
  })(window, document, 'script', dataLayerName, gtmId);
};

function createTrackPage(callback) {
  return ({ fullPath }, title, payload = {}) => {
    const baseUrl = store.state.config.baseUrl || '/';
    const internalPath = baseUrl.endsWith('/') ? fullPath.slice(1) : fullPath;
    const event = {
      event: 'VirtualPageview',
      virtualPageURL: baseUrl + internalPath,
      virtualPageTitle: title,
      ...payload
    };
    callback(event);
  };
}

/**
 *
 * @param {String} customerAnalyticsId
 * @param {String} gtmId
 * @param {String} dataLayerName
 */
export const initGTM = function(customerAnalyticsId, gtmId, type = 'default', dataLayerName = 'dataLayer') {
  window.elasticCarsearch = {
    toggleAnalytics(value) {
      const disableString = `ga-disable-${customerAnalyticsId}`;
      const baseDisableString = `ga-disable-UA-209498-64`;

      if ((value === undefined && window[disableString]) || value) {
        delete window[disableString];
        delete window[baseDisableString];
      } else {
        window[disableString] = true;
        window[baseDisableString] = true;
      }
    }
  };

  if (document.cookie.indexOf(`ga-disable-${customerAnalyticsId}` + '=true') > -1) {
    window.elasticCarsearch.toggleAnalytics(false);
  }

  if (type === 'default') {
    window[dataLayerName] = window[dataLayerName] || [];
    const enqueue = tracking(window[dataLayerName]);

    return {
      trackPage: createTrackPage(enqueue)
    };
  } else if (type === 'load_gtm_script') {
    const { dataLayer } = registerGTM(gtmId, dataLayerName, customerAnalyticsId);
    const enqueue = tracking(dataLayer);

    return {
      trackPage: createTrackPage(enqueue)
    };
  } else if (['usercentrics_cmpv1', 'usercentrics_cmpv2'].includes(type)) {
    const tracker = {
      trackPage: noop
    };

    let enqueue;
    window.addEventListener('onConsentStatusChange', event => {
      // !IMPORTANT!
      // event.data is usercentrics_cmpv1
      // event.detail is usercentrics_cmpv2
      const consents = event.data || event.detail || {};
      if (consents['Google Analytics'] || consents['Google Tag Manager']) {
        if (!enqueue) {
          window[dataLayerName] = window[dataLayerName] || [];
          enqueue = tracking(window[dataLayerName]);
        }
        tracker.trackPage = createTrackPage(enqueue);
      } else {
        tracker.trackPage = noop;
      }
    });

    return tracker;
  }
};
