/**
 * @param {string} url
 * @param {string} key
 * @param {string} value
 * @return {string}
 */
export function addQueryParamToUrl(url, key, value) {
  const seperator = url.includes('?') ? '&' : '?';
  return `${url}${seperator}${key}=${encodeURIComponent(value)}`;
}
