/**
 * Creates a container to hold your value and track if it has been changed.
 * The `commit` function can be called to persist the changes somewhere and flush
 * the changed state.
 *
 * @template V
 * @param {V} startValue
 * @param {(value: V) => void} commitFunction
 */
function createValueContainer(startValue, commitFunction) {
  let valueChanged = false;
  let value = startValue;

  return {
    get value() {
      return value;
    },
    set value(newValue) {
      value = newValue;
      valueChanged = true;
    },
    commit() {
      if (valueChanged) {
        commitFunction(value);
        valueChanged = false;
      }
    }
  };
}

/** @typedef {{ key: string }[]} MultiselectStringsObject */
/** @typedef {{ key: string }[]} MultiselectNumbersObject */
/**
 * @typedef StoreGetters
 * @property {string[]} fahrzeugart
 * @property {string} interne_nummer
 * @property {string} modelVariant
 * @property {string} custom1
 * @property {MultiselectStringsObject} custom2
 * @property {MultiselectStringsObject} make
 * @property {MultiselectStringsObject} model
 * @property {MultiselectStringsObject} getriebeart
 * @property {MultiselectStringsObject} kraftstoffart
 * @property {number[]} ez
 * @property {number[]} bruttopreis
 * @property {number[]} kilometer
 * @property {number[]} leistung_ps
 * @property {MultiselectStringsObject} mwst
 * @property {string[]} fahrzeugtyp
 * @property {string[]} basecolor
 * @property {string[]} metallic
 * @property {string[]} ausstattung
 * @property {MultiselectStringsObject} interieurfarbe
 * @property {MultiselectStringsObject} interieurtyp
 * @property {MultiselectStringsObject} klima
 * @property {MultiselectNumbersObject} sitze
 * @property {MultiselectNumbersObject} tueren
 * @property {string[]} sale
 */
/**
 * @typedef Store
 * @property {StoreGetters} getters
 */
/**
 * @param {{ $store: Store, context: VueContext }} param0
 */
export const createFormData = ({ $store, context }) => {
  const fahrzeugart = createValueContainer($store.getters.fahrzeugart, fahrzeugart =>
    $store.commit('setFahrzeugart', [...fahrzeugart])
  );
  const interne_nummer = createValueContainer($store.getters.interne_nummer, interne_nummer =>
    $store.commit('setInterne_nummer', interne_nummer)
  );
  const modelVariant = createValueContainer($store.getters.modelVariant, modelVariant =>
    $store.commit('setModelVariant', modelVariant)
  );
  const custom1 = createValueContainer($store.getters.custom1, custom1 => $store.commit('setCustom1', custom1));
  const custom2 = createValueContainer($store.getters.custom2, custom2 => $store.commit('setCustom2', custom2));
  const make = createValueContainer($store.getters.make, make => $store.commit('setMake', make));
  const model = createValueContainer($store.getters.model, model => $store.commit('setModel', model));
  const getriebeart = createValueContainer($store.getters.getriebeart, getriebeart =>
    $store.commit('setGetriebeart', getriebeart)
  );
  const kraftstoffart = createValueContainer($store.getters.kraftstoffart, kraftstoffart =>
    $store.commit('setKraftstoffart', kraftstoffart)
  );
  const ez = createValueContainer($store.getters.ez, ez => $store.commit('setEz', ez));
  const bruttopreis = createValueContainer($store.getters.bruttopreis, bruttopreis =>
    $store.commit('setBruttopreis', bruttopreis)
  );
  const kilometer = createValueContainer($store.getters.kilometer, kilometer =>
    $store.commit('setKilometer', kilometer)
  );
  const leistung_ps = createValueContainer($store.getters.leistung_ps, leistung_ps =>
    $store.commit('setLeistung_ps', leistung_ps)
  );
  const mwst = createValueContainer($store.getters.mwst, mwst => $store.commit('setMwst', mwst));
  const fahrzeugtyp = createValueContainer($store.getters.fahrzeugtyp, fahrzeugtyp =>
    $store.commit('setFahrzeugtyp', fahrzeugtyp)
  );
  const basecolor = createValueContainer($store.getters.basecolor, basecolor => {
    const changedColors = basecolor.map(item => context.$t(`colorsBack.${item}`));
    $store.commit('setBasecolor', changedColors);
  });
  const metallic = createValueContainer($store.getters.metallic, metallic => $store.commit('setMetallic', metallic));
  const ausstattung = createValueContainer($store.getters.ausstattung, ausstattung =>
    $store.commit('setAusstattung', ausstattung)
  );
  const interieurfarbe = createValueContainer($store.getters.interieurfarbe, interieurfarbe =>
    $store.commit('setInterieurfarbe', interieurfarbe)
  );
  const interieurtyp = createValueContainer($store.getters.interieurtyp, interieurtyp =>
    $store.commit('setInterieurtyp', interieurtyp)
  );
  const klima = createValueContainer($store.getters.klima, klima => $store.commit('setKlima', klima));
  const sitze = createValueContainer($store.getters.sitze, sitze => $store.commit('setSitze', sitze));
  const tueren = createValueContainer($store.getters.tueren, tueren => $store.commit('setTueren', tueren));
  const sale = createValueContainer($store.getters.sale, sale => $store.commit('setSale', sale));

  // public
  return {
    get fahrzeugart() {
      return fahrzeugart.value;
    },
    set fahrzeugart(value) {
      fahrzeugart.value = value;
    },
    get interne_nummer() {
      return interne_nummer.value;
    },
    set interne_nummer(value) {
      interne_nummer.value = value;
    },
    get modelVariant() {
      return modelVariant.value;
    },
    set modelVariant(value) {
      modelVariant.value = value;
    },
    get custom1() {
      return custom1.value;
    },
    set custom1(value) {
      custom1.value = value;
    },
    get custom2() {
      return custom2.value;
    },
    set custom2(value) {
      custom2.value = value;
    },
    get make() {
      return make.value;
    },
    set make(value) {
      make.value = value;
    },
    get model() {
      return model.value;
    },
    set model(value) {
      model.value = value;
    },
    get getriebeart() {
      return getriebeart.value;
    },
    set getriebeart(value) {
      getriebeart.value = value;
    },
    get kraftstoffart() {
      return kraftstoffart.value;
    },
    set kraftstoffart(value) {
      kraftstoffart.value = value;
    },
    get ez() {
      return ez.value;
    },
    set ez(value) {
      ez.value = value;
    },
    get bruttopreis() {
      return bruttopreis.value;
    },
    set bruttopreis(value) {
      bruttopreis.value = value;
    },
    get kilometer() {
      return kilometer.value;
    },
    set kilometer(value) {
      kilometer.value = value;
    },
    get leistung_ps() {
      return leistung_ps.value;
    },
    set leistung_ps(value) {
      leistung_ps.value = value;
    },
    get mwst() {
      return mwst.value;
    },
    set mwst(value) {
      mwst.value = value;
    },
    get fahrzeugtyp() {
      return fahrzeugtyp.value;
    },
    set fahrzeugtyp(value) {
      fahrzeugtyp.value = value;
    },
    get basecolor() {
      return basecolor.value;
    },
    set basecolor(value) {
      basecolor.value = value;
    },
    get metallic() {
      return metallic.value;
    },
    set metallic(value) {
      metallic.value = value;
    },
    get ausstattung() {
      return ausstattung.value;
    },
    set ausstattung(value) {
      ausstattung.value = value;
    },
    get interieurfarbe() {
      return interieurfarbe.value;
    },
    set interieurfarbe(value) {
      interieurfarbe.value = value;
    },
    get interieurtyp() {
      return interieurtyp.value;
    },
    set interieurtyp(value) {
      interieurtyp.value = value;
    },
    get klima() {
      return klima.value;
    },
    set klima(value) {
      klima.value = value;
    },
    get sitze() {
      return sitze.value;
    },
    set sitze(value) {
      sitze.value = value;
    },
    get tueren() {
      return tueren.value;
    },
    set tueren(value) {
      tueren.value = value;
    },
    get sale() {
      return sale.value;
    },
    set sale(value) {
      sale.value = value;
    },
    save() {
      [
        fahrzeugart,
        interne_nummer,
        modelVariant,
        custom1,
        custom2,
        make,
        model,
        getriebeart,
        kraftstoffart,
        ez,
        bruttopreis,
        kilometer,
        leistung_ps,
        mwst,
        fahrzeugtyp,
        basecolor,
        metallic,
        ausstattung,
        interieurfarbe,
        interieurtyp,
        klima,
        sitze,
        tueren,
        sale
      ].forEach(valueContainer => valueContainer.commit());
    }
  };
};
