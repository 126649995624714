import Vue from 'vue';
import Vuex from 'vuex';
import { debounce } from 'lodash-es';
import VueMeta from 'vue-meta';
import VueSilentbox from 'vue-silentbox';
import VShowSlide from 'v-show-slide';
import VueMq from 'vue-mq';
import SkipLinks from 'vue-skip-links/src/main';
import DataBreakpoints from 'v-data-breakpoints';
import store from '@/store';
import i18n from '@/i18n';
import { loadConfigByElement } from '@/model/config';
import { initGTM } from '@/gtm';
import client from '@/client';

Vue.config.productionTip = false;

Vue.use(Vuex);
Vue.use(VueMeta);
Vue.use(VueSilentbox);
Vue.use(VShowSlide);
Vue.use(DataBreakpoints, { debounce });
Vue.use(VueMq, {
  breakpoints: {
    phone: 480,
    tablet: 768,
    not_mobile: Infinity
  },
  defaultBreakpoint: 'phone'
});

const element = document.getElementById('lia-carsearch');
const newCarElement = document.getElementById('lia-newcarwidget');
const querySearchElement = document.getElementById('lia-querysearchwidget');
const basicSearchElement = document.getElementById('lia-basicsearch');
const miniSearchElement = document.getElementById('lia-minisearch');
const hashSearchElement = document.getElementById('lia-hashsearchwidget');
const clientElement = document.getElementById('lia-client');

const toBeLoadedApps = [
  element,
  newCarElement,
  querySearchElement,
  basicSearchElement,
  miniSearchElement,
  hashSearchElement,
  clientElement
]
  .filter(Boolean)
  .map(el => loadConfigByElement(store, el));

Promise.all(toBeLoadedApps).then(() => {
  const { enabled, type, dataLayerName, customerAnalyticsId, gtmId } = store.state.config.gtm;
  i18n.locale = store.state.config.locale;
  Vue.use({
    install(Vue) {
      Vue.prototype.$liaPrefix = 'lia-';
      Vue.prototype.$gtm = enabled ? initGTM(customerAnalyticsId, gtmId, type, dataLayerName) : { trackPage() {} };
      client.$vue = Vue;
      client.$store = store;
    }
  });
  Vue.use(SkipLinks, {
    activeClass: `${Vue.prototype.$liaPrefix}is-visible`
  });

  if (element) {
    Promise.all([import('@/router'), import('@/App.vue')]).then(([{ default: createRouter }, { default: App }]) => {
      const routerOptions = {
        offsetX: store.state.config.offsetX,
        offsetY: store.state.config.offsetY,
        offsetContainer: store.state.config.offsetContainer,
        disableHomeScroll: store.state.config.disableHomeScroll
      };
      if (store.state.config.baseUrl) {
        routerOptions.base = store.state.config.baseUrl;
      }
      new Vue({
        i18n,
        router: createRouter(routerOptions),
        store,
        render: h => h(App)
      }).$mount(element);
    });
  }

  if (newCarElement) {
    import('@/NewCarWidget.vue').then(({ default: NewCarWidget }) => {
      new Vue({
        i18n,
        store,
        render: h => h(NewCarWidget)
      }).$mount(newCarElement);
    });
  }

  if (querySearchElement) {
    import('@/QuerySearchWidget.vue').then(({ default: QuerySearchWidget }) => {
      new Vue({
        i18n,
        store,
        render: h => h(QuerySearchWidget)
      }).$mount(querySearchElement);
    });
  }

  if (basicSearchElement) {
    import('@/BasicSearch.vue').then(({ default: BasicSearch }) => {
      new Vue({
        i18n,
        store,
        render: h => h(BasicSearch)
      }).$mount(basicSearchElement);
    });
  }

  if (miniSearchElement) {
    import('@/MiniSearch.vue').then(({ default: MiniSearch }) => {
      new Vue({
        i18n,
        store,
        render: h => h(MiniSearch)
      }).$mount(miniSearchElement);
    });
  }

  if (hashSearchElement) {
    import('@/HashSearchWidget.vue').then(({ default: HashSearchWidget }) => {
      new Vue({
        i18n,
        store,
        render: h => h(HashSearchWidget)
      }).$mount(hashSearchElement);
    });
  }

  if (clientElement) {
    new Vue({
      i18n,
      store
    });
  }
});

window._LIA_CARSEARCH_ = client;
