import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { isEmpty, isEqual } from 'lodash-es';
import { SORTBY_CREATEDAT_DESC, CAR_AGE_TYPE_NEW } from '@/constants';
import client from '@/client';

Vue.use(Vuex);

function termsWrap(name, field) {
  const innerObject = {};
  if (field[0]) {
    if (field[0].key) {
      field = field.map(item => item.key);
    }
  }
  innerObject[name] = field;
  return { terms: innerObject };
}

const state = {
  sortBy: '',
  urlQuery: '',
  count: 0,
  chosenFilterList: [],
  aggregations: {},
  staticAggregations: {},
  staticAggs: {
    aggs: {
      make: {
        terms: { field: 'make', order: [{ _term: 'asc' }], size: 100 },
        aggs: {
          model: {
            terms: { field: 'model', order: [{ _term: 'asc' }], size: 100 }
          }
        }
      },
      custom2: {
        terms: { field: 'custom2', order: [{ _term: 'asc' }], size: 100 }
      },
      basecolor: {
        terms: { field: 'basecolor', order: [{ _term: 'asc' }], size: 50 }
      },
      fahrzeugart: {
        terms: { field: 'fahrzeugart', order: [{ _term: 'asc' }] }
      },
      category: {
        terms: { field: 'category', order: [{ _term: 'asc' }], size: 100 }
      },
      klima: { terms: { field: 'klima', order: [{ _term: 'asc' }] } },
      interieurtyp: {
        terms: { field: 'interieurtyp', order: [{ _term: 'asc' }] }
      },
      interieurfarbe: {
        terms: { field: 'interieurfarbe', order: [{ _term: 'asc' }] }
      },
      sitze: { terms: { field: 'sitze', order: [{ _term: 'asc' }] } },
      tueren: { terms: { field: 'tueren', order: [{ _term: 'asc' }] } },
      getriebeart: {
        terms: { field: 'getriebeart', order: [{ _term: 'asc' }] }
      },
      kraftstoffart: {
        terms: { field: 'kraftstoffart', order: [{ _term: 'asc' }] }
      },
      mwst: { terms: { field: 'mwst', order: [{ _term: 'asc' }] } },
      bruttopreis: {
        range: { field: 'bruttopreis', ranges: [{ from: 0 }] },
        aggs: {
          max: { max: { field: 'bruttopreis' } },
          min: { min: { field: 'bruttopreis' } }
        }
      },
      ez: {
        range: { field: 'ez', ranges: [{ from: 0 }] },
        aggs: { max: { max: { field: 'ez' } }, min: { min: { field: 'ez' } } }
      },
      leistung_ps: {
        range: { field: 'leistung_ps', ranges: [{ from: 0 }] },
        aggs: {
          max: { max: { field: 'leistung_ps' } },
          min: { min: { field: 'leistung_ps' } }
        }
      },
      kilometer: {
        range: { field: 'kilometer', ranges: [{ from: 0 }] },
        aggs: {
          max: { max: { field: 'kilometer' } },
          min: { min: { field: 'kilometer' } }
        }
      }
    }
  },
  aggs: {
    aggs: {
      bruttopreis: {
        range: { field: 'bruttopreis', ranges: [{ from: 0 }] },
        aggs: {
          max: { max: { field: 'bruttopreis' } },
          min: { min: { field: 'bruttopreis' } }
        }
      },
      ez: {
        range: { field: 'ez', ranges: [{ from: 0 }] },
        aggs: { max: { max: { field: 'ez' } }, min: { min: { field: 'ez' } } }
      },
      leistung_ps: {
        range: { field: 'leistung_ps', ranges: [{ from: 0 }] },
        aggs: {
          max: { max: { field: 'leistung_ps' } },
          min: { min: { field: 'leistung_ps' } }
        }
      },
      kilometer: {
        range: { field: 'kilometer', ranges: [{ from: 0 }] },
        aggs: {
          max: { max: { field: 'kilometer' } },
          min: { min: { field: 'kilometer' } }
        }
      }
    }
  },
  onlyPrice: {
    aggs: {
      bruttopreis: {
        range: { field: 'bruttopreis', ranges: [{ from: 0 }] },
        aggs: {
          min: { min: { field: 'bruttopreis' } }
        }
      }
    }
  },
  ausstattungCheck: [
    'abs',
    'allradantrieb',
    'anhaengerkupplung',
    'bluetooth',
    'einparkhilfe',
    'fensterheber',
    'wegfahrsperre',
    'navigationssystem',
    'nebelscheinwerfer',
    'partikelfilter',
    'regensensor',
    'schiebedach',
    'servolenkung',
    'sitzheizung',
    'standheizung',
    'start_stop_automatik',
    'tempomat',
    'xenonscheinwerfer',
    'zentralverriegelung'
  ],
  fahrzeugart: [],
  interne_nummer: '',
  modelVariant: '',
  custom1: '',
  custom2: [],
  make: [],
  model: [],
  getriebeart: [],
  kraftstoffart: [],
  ezFullRange: [],
  ez: [],
  bruttopreisFullRange: [],
  bruttopreis: [],
  kilometerFullRange: [],
  kilometer: [],
  leistung_psFullRange: [],
  leistung_ps: [],
  mwst: [],
  fahrzeugtyp: [],
  basecolor: [],
  metallic: [],
  ausstattung: [],
  interieurfarbe: [],
  interieurtyp: [],
  klima: [],
  sitze: [],
  tueren: [],
  sale: []
};

const getters = {
  sortBy(state) {
    return state.sortBy;
  },
  urlQuery(state) {
    return state.urlQuery;
  },
  count(state) {
    return state.count;
  },
  aggregations(state) {
    return state.aggregations;
  },
  staticAggregations(state) {
    return state.staticAggregations;
  },
  aggs(state) {
    return state.aggs;
  },
  onlyPrice(state) {
    return state.onlyPrice;
  },
  staticAggs(state) {
    return state.staticAggs;
  },
  ausstattungCheck(state) {
    return state.ausstattungCheck;
  },
  fahrzeugart(state) {
    return state.fahrzeugart;
  },
  make(state) {
    return state.make;
  },
  model(state) {
    return state.model;
  },
  getriebeart(state) {
    return state.getriebeart;
  },
  kraftstoffart(state) {
    return state.kraftstoffart;
  },
  ez(state) {
    return state.ez;
  },
  bruttopreis(state) {
    return state.bruttopreis;
  },
  kilometer(state) {
    return state.kilometer;
  },
  leistung_ps(state) {
    return state.leistung_ps;
  },
  ezFullRange(state) {
    return state.ezFullRange;
  },
  bruttopreisFullRange(state) {
    return state.bruttopreisFullRange;
  },
  kilometerFullRange(state) {
    return state.kilometerFullRange;
  },
  leistung_psFullRange(state) {
    return state.leistung_psFullRange;
  },
  mwst(state) {
    return state.mwst;
  },
  fahrzeugtyp(state) {
    return state.fahrzeugtyp;
  },
  basecolor(state) {
    return state.basecolor;
  },
  ausstattung(state) {
    return state.ausstattung;
  },
  interieurfarbe(state) {
    return state.interieurfarbe;
  },
  interieurtyp(state) {
    return state.interieurtyp;
  },
  klima(state) {
    return state.klima;
  },
  sitze(state) {
    return state.sitze;
  },
  tueren(state) {
    return state.tueren;
  },
  sale(state) {
    return state.sale;
  },
  metallic(state) {
    return state.metallic;
  },
  interne_nummer(state) {
    return state.interne_nummer;
  },
  custom1(state) {
    return state.custom1;
  },
  custom2(state) {
    return state.custom2;
  },
  modelVariant(state) {
    return state.modelVariant;
  },
  chosenFilterList(state) {
    return state.chosenFilterList;
  }
};

const mutations = {
  setSortBy(state, sortBy) {
    state.sortBy = sortBy;
  },
  setUrlQuery(state, urlQuery) {
    state.urlQuery = urlQuery;
  },
  setCount(state, count) {
    state.count = count;
  },
  setStaticAggregations(state, staticAggregations) {
    state.staticAggregations = staticAggregations;
  },
  setAggregations(state, aggregations) {
    state.aggregations = aggregations;
  },
  setFahrzeugart(state, fahrzeugart) {
    state.fahrzeugart = fahrzeugart;
  },
  setMake(state, make) {
    state.make = make;
  },
  setModel(state, model) {
    state.model = model;
  },
  setGetriebeart(state, getriebeart) {
    state.getriebeart = getriebeart;
  },
  setKraftstoffart(state, kraftstoffart) {
    state.kraftstoffart = kraftstoffart;
  },
  setEz(state, ez) {
    state.ez = ez;
  },
  setBruttopreis(state, bruttopreis) {
    state.bruttopreis = bruttopreis;
  },
  setKilometer(state, kilometer) {
    state.kilometer = kilometer;
  },
  setLeistung_ps(state, leistung_ps) {
    state.leistung_ps = leistung_ps;
  },
  setMwst(state, mwst) {
    state.mwst = mwst;
  },
  setFahrzeugtyp(state, fahrzeugtyp) {
    state.fahrzeugtyp = fahrzeugtyp;
  },
  setBasecolor(state, basecolor) {
    state.basecolor = basecolor;
  },
  setMetallic(state, metallic) {
    state.metallic = metallic;
  },
  setAusstattung(state, ausstattung) {
    state.ausstattung = ausstattung;
  },
  setInterieurfarbe(state, interieurfarbe) {
    state.interieurfarbe = interieurfarbe;
  },
  setInterieurtyp(state, interieurtyp) {
    state.interieurtyp = interieurtyp;
  },
  setKlima(state, klima) {
    state.klima = klima;
  },
  setSitze(state, sitze) {
    state.sitze = sitze;
  },
  setTueren(state, tueren) {
    state.tueren = tueren;
  },
  setSale(state, sale) {
    state.sale = sale;
  },
  setInterne_nummer(state, interne_nummer) {
    state.interne_nummer = interne_nummer;
  },
  setCustom1(state, custom1) {
    state.custom1 = custom1;
  },
  setCustom2(state, custom2) {
    state.custom2 = custom2;
  },
  setModelVariant(state, modelVariant) {
    state.modelVariant = modelVariant;
  },
  setChosenFilterList(state, chosenFilterList) {
    state.chosenFilterList = chosenFilterList;
  },
  setEzFullRange(state, ezFullRange) {
    state.ezFullRange = ezFullRange;
  },
  setBruttopreisFullRange(state, bruttopreisFullRange) {
    state.bruttopreisFullRange = bruttopreisFullRange;
  },
  setKilometerFullRange(state, kilometerFullRange) {
    state.kilometerFullRange = kilometerFullRange;
  },
  setLeistung_psFullRange(state, leistung_psFullRange) {
    state.leistung_psFullRange = leistung_psFullRange;
  }
};

const actions = {
  // fires at creation of page, to fill the fields
  async InitialAggregations({ commit }, updateCount) {
    const staticAggregations = await client.getAggregation();
    commit('setStaticAggregations', staticAggregations.data);
    if (updateCount) commit('setCount', staticAggregations.data.hits.total);
    const aggs = staticAggregations.data.aggregations;
    commit('setKilometerFullRange', [0, aggs.kilometer.buckets[0].max.value]);
    commit('setBruttopreisFullRange', [aggs.bruttopreis.buckets[0].min.value, aggs.bruttopreis.buckets[0].max.value]);
    commit('setLeistung_psFullRange', [aggs.leistung_ps.buckets[0].min.value, aggs.leistung_ps.buckets[0].max.value]);
    commit('setEzFullRange', [
      new Date(aggs.ez.buckets[0].min.value_as_string).getFullYear(),
      new Date(aggs.ez.buckets[0].max.value_as_string).getFullYear()
    ]);
    commit('setKilometer', [0, aggs.kilometer.buckets[0].max.value]);
    commit('setBruttopreis', [aggs.bruttopreis.buckets[0].min.value, aggs.bruttopreis.buckets[0].max.value]);
    commit('setLeistung_ps', [aggs.leistung_ps.buckets[0].min.value, aggs.leistung_ps.buckets[0].max.value]);
    commit('setEz', [
      new Date(aggs.ez.buckets[0].min.value_as_string).getFullYear(),
      new Date(aggs.ez.buckets[0].max.value_as_string).getFullYear()
    ]);
  },
  // fires after every set filter, to get new count and fill changing fields
  async getAggregations({ commit }) {
    const response = await client.getSearchresults();
    commit('setAggregations', response.data);
    commit('setCount', response.data.hits.total);
  },
  // sorted search, on the results page
  async sortedResultSearch({ commit, getters, dispatch, rootState }, params = {}) {
    const queryObject = await dispatch('fillQuery');
    const queryParams = [];
    if (params.size) {
      queryParams.push(`size=${params.size}`);
    }
    if (params.from) {
      queryParams.push(`from=${params.from}`);
    }
    if (params.sort) {
      queryParams.push(`sort=${encodeURIComponent(params.sort)}`);
    }
    const host = rootState.config.server;
    const customRootPath = rootState.config.index;
    const queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
    let url = `${host}${customRootPath}/_search${queryString}`;

    const response = await axios.post(url, {
      aggs: getters.onlyPrice.aggs,
      query: queryObject.query
    });
    commit('setCount', response.data.hits.total);
    return response.data;
  },
  async newestCarSearch({ rootState }, params) {
    let url = `${rootState.config.server}${rootState.config.index}/_search?sort=${encodeURIComponent(
      SORTBY_CREATEDAT_DESC
    )}`;
    if (params && params.size) url += `&size=${params.size}`;

    const response = await axios.post(url);
    return response.data;
  },
  async querySearch({ rootState }, params) {
    let url = `${rootState.config.server}${rootState.config.index}/_count`;
    const queryObject = {
      query: {
        bool: {
          filter: {
            bool: {
              must: [
                { prefix: { vendor: rootState.config.vendor } },
                { match: { name: { query: params.term, operator: 'or' } } }
              ]
            }
          }
        }
      }
    };

    const response = await axios.post(url, queryObject);

    return response.data.count;
  },
  async carByIdSearch({ rootState }, id) {
    const queryObject = {
      query: {
        bool: {
          filter: {
            bool: {
              must: [{ prefix: { vendor: rootState.config.vendor } }, { term: { interne_nummer: id } }]
            }
          }
        }
      }
    };
    let url = `${rootState.config.server}${rootState.config.index}/_search`;

    const response = await axios.post(url, queryObject);
    return response.data;
  },
  // search for similar cars on detail page
  async moreLikeIdSearch({ rootState }, result) {
    const queryObject = {
      query: {
        more_like_this: {
          fields: ['make', 'model', 'kraftstoffart', 'getriebeart'],
          like: {
            _index: result._index,
            _type: result._type,
            _id: result._id
          },
          min_term_freq: 1
        }
      },
      size: 3
    };
    let url = `${rootState.config.server}${rootState.config.index}/_search`;

    const response = await axios.post(url, queryObject);
    return response.data;
  },
  async moreLikeMakeModelSearch({ rootState }, searchQuery) {
    const queryObject = {
      query: {
        match: {
          name: {
            query: searchQuery,
            operator: 'or'
          }
        }
      },
      size: 3
    };
    let url = `${rootState.config.server}${rootState.config.index}/_search`;

    const response = await axios.post(url, queryObject);
    return response.data;
  },
  createChosenFilterList({ commit, getters }) {
    const chosenFilterListObject = {};
    if (!isEmpty(getters.fahrzeugart)) {
      chosenFilterListObject['fahrzeugart'] = getters.fahrzeugart;
    }
    if (!isEmpty(getters.make)) {
      chosenFilterListObject['make'] = getters.make;
    }
    if (!isEmpty(getters.model)) {
      chosenFilterListObject['model'] = getters.model;
    }
    if (!isEmpty(getters.getriebeart)) {
      chosenFilterListObject['getriebeart'] = getters.getriebeart;
    }
    if (!isEmpty(getters.kraftstoffart)) {
      chosenFilterListObject['kraftstoffart'] = getters.kraftstoffart;
    }
    if (!isEmpty(getters.modelVariant)) {
      chosenFilterListObject['modelVariant'] = getters.modelVariant;
    }
    if (!isEmpty(getters.mwst)) {
      chosenFilterListObject['mwst'] = getters.mwst;
    }
    if (!isEmpty(getters.fahrzeugtyp)) {
      chosenFilterListObject['fahrzeugtyp'] = getters.fahrzeugtyp;
    }
    if (!isEmpty(getters.klima)) {
      chosenFilterListObject['klima'] = getters.klima;
    }
    if (!isEmpty(getters.sitze)) {
      chosenFilterListObject['sitze'] = getters.sitze;
    }
    if (!isEmpty(getters.tueren)) {
      chosenFilterListObject['tueren'] = getters.tueren;
    }
    if (!isEmpty(getters.sale)) {
      chosenFilterListObject['sale'] = getters.sale;
    }
    if (!isEmpty(getters.interieurfarbe)) {
      chosenFilterListObject['interieurfarbe'] = getters.interieurfarbe;
    }
    if (!isEmpty(getters.interieurtyp)) {
      chosenFilterListObject['interieurtyp'] = getters.interieurtyp;
    }
    if (!isEmpty(getters.custom1)) {
      chosenFilterListObject['custom1'] = getters.custom1;
    }
    if (!isEmpty(getters.custom2)) {
      chosenFilterListObject['custom2'] = getters.custom2;
    }
    if (!isEmpty(getters.basecolor)) {
      chosenFilterListObject['basecolor'] = getters.basecolor;
    }
    if (!isEmpty(getters.ausstattung)) {
      chosenFilterListObject['ausstattung'] = getters.ausstattung;
    }
    if (!isEmpty(getters.bruttopreis) && !isEqual(getters.bruttopreis, getters.bruttopreisFullRange)) {
      chosenFilterListObject['bruttopreis'] = getters.bruttopreis;
    }
    if (!isEmpty(getters.kilometer) && !isEqual(getters.kilometer, getters.kilometerFullRange)) {
      chosenFilterListObject['kilometer'] = getters.kilometer;
    }
    if (!isEmpty(getters.leistung_ps) && !isEqual(getters.leistung_ps, getters.leistung_psFullRange)) {
      chosenFilterListObject['leistung_ps'] = getters.leistung_ps;
    }
    if (!isEmpty(getters.ez) && !isEqual(getters.ez, getters.ezFullRange)) {
      chosenFilterListObject['ez'] = getters.ez;
    }
    if (getters.modelVariant !== '') {
      chosenFilterListObject['modelVariant'] = getters.modelVariant;
    }
    commit('setChosenFilterList', chosenFilterListObject);
  },
  fillQuery({ getters, rootState }) {
    const queryShouldHaveCriteria = [];
    if (!isEmpty(getters.ez) && !isEqual(getters.ez, getters.ezFullRange)) {
      // is everything less than next year, because equal current year only gets 01.01
      queryShouldHaveCriteria.push({
        range: { ez: { gte: getters.ez[0], lt: getters.ez[1] + 1 } }
      });
      if (isEmpty(getters.fahrzeugart) || getters.fahrzeugart.includes(CAR_AGE_TYPE_NEW)) {
        queryShouldHaveCriteria.push({
          bool: { must_not: { exists: { field: 'ez' } } }
        });
      }
    }

    const queryMustHaveCriteria = [{ prefix: { vendor: rootState.config.vendor } }];
    if (!isEmpty(getters.fahrzeugart)) {
      queryMustHaveCriteria.push(termsWrap('fahrzeugart', getters.fahrzeugart));
    }
    if (!isEmpty(getters.make)) {
      queryMustHaveCriteria.push(termsWrap('make', getters.make));
    }
    if (!isEmpty(getters.model)) {
      queryMustHaveCriteria.push(termsWrap('model', getters.model));
    }
    if (!isEmpty(getters.getriebeart)) {
      queryMustHaveCriteria.push(termsWrap('getriebeart', getters.getriebeart));
    }
    if (!isEmpty(getters.kraftstoffart)) {
      queryMustHaveCriteria.push(termsWrap('kraftstoffart', getters.kraftstoffart));
    }
    if (!isEmpty(getters.mwst)) {
      queryMustHaveCriteria.push(termsWrap('mwst', getters.mwst));
    }
    if (!isEmpty(getters.fahrzeugtyp)) {
      const fahrzeugtypCategoryShortMap = {
        'Sportwagen/Coupé': ['sports_car'],
        'Van/Kleinbus': ['van'],
        Kombi: ['estate_car', 'estate_minibus_up_to9_seats_van'],
        Transporter: [
          'car_carrier_semi_trailer',
          'car_carrier_trailer',
          'car_carrier_truck',
          'car_carrier_van',
          'cattle_truck_semi_trailer',
          'glass_transport_superstructure_van',
          'long_material_transporter_semi_trailer',
          'long_material_transporter_trailer',
          'other_van_up_to7500'
        ],
        Kastenwagen: [
          'box_type_delivery_van',
          'high_and_long_box_type_delivery_van',
          'high_box_type_delivery_van',
          'long_box_type_delivery_van',
          'refrigerator_box'
        ],
        'SUV/Geländewagen/Pickup': ['off_road'],
        Limousine: ['limousine'],
        Kleinwagen: ['small_car'],
        'Cabrio/Roadster': ['cabrio'],
        Andere: [
          'motor_homes_and_other',
          'other_car',
          'other_motorbike',
          'other_truck_over7500',
          'over7500_three_sided_tipper'
        ],
        'Wohnmobil andere': ['motor_homes_and_other', 'motor_homes_and_pickup', 'caravan', 'van_motorhome']
      };

      let fahrzeugtyp = getters.fahrzeugtyp
        .map(typ => {
          if (!fahrzeugtypCategoryShortMap.hasOwnProperty(typ)) {
            throw new Error(`Fahrzeugtyp ${typ} konnte nicht auf eine kategorie gemapped werden`);
          }

          return fahrzeugtypCategoryShortMap[typ];
        })
        .flat();

      if (!isEmpty(fahrzeugtyp)) {
        queryMustHaveCriteria.push(termsWrap('category_short', fahrzeugtyp));
      }
    }
    if (!isEmpty(getters.klima)) {
      queryMustHaveCriteria.push(termsWrap('klima', getters.klima));
    }
    if (!isEmpty(getters.sitze)) {
      queryMustHaveCriteria.push(termsWrap('sitze', getters.sitze));
    }
    if (!isEmpty(getters.tueren)) {
      queryMustHaveCriteria.push(termsWrap('tueren', getters.tueren));
    }
    if (!isEmpty(getters.interieurfarbe)) {
      queryMustHaveCriteria.push(termsWrap('interieurfarbe', getters.interieurfarbe));
    }
    if (!isEmpty(getters.interieurtyp)) {
      queryMustHaveCriteria.push(termsWrap('interieurtyp', getters.interieurtyp));
    }
    if (!isEmpty(getters.custom2)) {
      queryMustHaveCriteria.push(termsWrap('custom2', getters.custom2));
    }
    if (!isEmpty(getters.basecolor)) {
      queryMustHaveCriteria.push(termsWrap('basecolor', getters.basecolor));
    }
    if (!isEmpty(getters.bruttopreis) && !isEqual(getters.bruttopreis, getters.bruttopreisFullRange)) {
      queryMustHaveCriteria.push({
        range: {
          bruttopreis: {
            gte: getters.bruttopreis[0],
            lte: getters.bruttopreis[1]
          }
        }
      });
    }
    if (!isEmpty(getters.leistung_ps) && !isEqual(getters.leistung_ps, getters.leistung_psFullRange)) {
      queryMustHaveCriteria.push({
        range: {
          leistung_ps: {
            gte: getters.leistung_ps[0],
            lte: getters.leistung_ps[1]
          }
        }
      });
    }
    if (!isEmpty(getters.kilometer) && !isEqual(getters.kilometer, getters.kilometerFullRange)) {
      if (getters.kilometer[0] === getters.kilometerFullRange[0]) {
        queryMustHaveCriteria.push({
          bool: {
            should: [
              {
                range: {
                  kilometer: {
                    gte: getters.kilometer[0],
                    lte: getters.kilometer[1]
                  }
                }
              },
              { bool: { must_not: { exists: { field: 'kilometer' } } } }
            ]
          }
        });
      } else {
        queryMustHaveCriteria.push({
          bool: {
            should: [
              {
                range: {
                  kilometer: {
                    gte: getters.kilometer[0],
                    lte: getters.kilometer[1]
                  }
                }
              }
            ]
          }
        });
      }
    }
    if (getters.modelVariant !== '') {
      queryMustHaveCriteria.push({
        match: {
          name: {
            query: `${getters.modelVariant}`,
            operator: 'or'
          }
        }
      });
    }
    const interneNummerFieldName = rootState.config.offerNumber.mapToField;
    if (getters[interneNummerFieldName] !== '') {
      queryMustHaveCriteria.push({
        match: {
          [interneNummerFieldName]: {
            query: `${getters[interneNummerFieldName]}`,
            operator: 'or'
          }
        }
      });
    }
    if (!isEmpty(getters.metallic)) {
      queryMustHaveCriteria.push({
        term: {
          metallic: true
        }
      });
    }

    if (!isEmpty(getters.sale)) {
      queryMustHaveCriteria.push({
        match: {
          ecs_benutzer_eigenschaften: {
            query: getters.sale.join(' '),
            operator: 'or'
          }
        }
      });
    }

    const queryFilterBy = (getters.ausstattung || []).map(ausstattung => {
      return {
        term: {
          [ausstattung]: true
        }
      };
    });

    return {
      query: {
        bool: {
          filter: {
            bool: {
              should: queryShouldHaveCriteria,
              must: queryMustHaveCriteria,
              filter: queryFilterBy
            }
          }
        }
      }
    };
  },
  reset({ commit, getters }) {
    commit('setFahrzeugart', []);
    commit('setInterne_nummer', '');
    commit('setModelVariant', '');
    commit('setCustom1', '');
    commit('setCustom2', []);
    commit('setMake', []);
    commit('setModel', []);
    commit('setGetriebeart', []);
    commit('setKraftstoffart', []);
    commit('setEz', getters.ezFullRange);
    commit('setBruttopreis', getters.bruttopreisFullRange);
    commit('setKilometer', getters.kilometerFullRange);
    commit('setLeistung_ps', getters.leistung_psFullRange);
    commit('setMwst', []);
    commit('setFahrzeugtyp', []);
    commit('setBasecolor', []);
    commit('setMetallic', []);
    commit('setAusstattung', []);
    commit('setInterieurfarbe', []);
    commit('setInterieurtyp', []);
    commit('setKlima', []);
    commit('setSitze', []);
    commit('setTueren', []);
    commit('setSale', []);
  }
};

export default {
  strict: true,
  state,
  getters,
  mutations,
  actions
};
