import { UPDATE_MODAL_HTML_CLASS } from '@/store/mutationTypes';

const state = {
  acc1Checked: true,
  acc2Checked: true,
  acc3Checked: true,
  modalHtmlClass: ''
};
const getters = {
  acc1Checked(state) {
    return state.acc1Checked;
  },
  acc2Checked(state) {
    return state.acc2Checked;
  },
  acc3Checked(state) {
    return state.acc3Checked;
  }
};
const mutations = {
  acc1Checked(state, acc1Checked) {
    state.acc1Checked = acc1Checked;
  },
  acc2Checked(state, acc2Checked) {
    state.acc2Checked = acc2Checked;
  },
  acc3Checked(state, acc3Checked) {
    state.acc3Checked = acc3Checked;
  },
  [UPDATE_MODAL_HTML_CLASS](state, modalHtmlClass) {
    state.modalHtmlClass = modalHtmlClass;
  }
};
const actions = {};

const viewState = {
  state,
  getters,
  mutations,
  actions
};

export default viewState;
