export const UPDATE_SORT = 'UPDATE_SORT';
export const UPDATE_CURRENT_PAGE = 'UPDATE_CURRENT_PAGE';
export const UPDATE_AGGREGATIONS = 'UPDATE_AGGREGATIONS';
export const UPDATE_QUERY = 'UPDATE_QUERY';
export const UPDATE_RESULTS = 'UPDATE_RESULTS';
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export const UPDATE_CONFIG_OFFSETX = 'UPDATE_CONFIG_OFFSETX';
export const UPDATE_CONFIG_OFFSETY = 'UPDATE_CONFIG_OFFSETY';
export const UPDATE_CONFIG_OFFSETCONTAINER = 'UPDATE_CONFIG_OFFSETCONTAINER';
export const UPDATE_CONFIG_BASE_URL = 'UPDATE_CONFIG_BASE_URL';
export const UPDATE_CONFIG_SALE_SEARCH_TERM = 'UPDATE_CONFIG_SALE_SEARCH_TERM';
export const UPDATE_CONFIG_LOCALE = 'UPDATE_CONFIG_LOCALE';
export const UPDATE_CONFIG_QUERY_KEY = 'UPDATE_CONFIG_QUERY_KEY';
export const UPDATE_CONFIG_DISABLE_HOME_SCROLL = 'UPDATE_CONFIG_DISABLE_HOME_SCROLL';
export const UPDATE_CONFIG_SEARCH_HASH = 'UPDATE_CONFIG_SEARCH_HASH';
export const UPDATE_CONFIG_MAX_CAR_NUMBER = 'UPDATE_CONFIG_MAX_CAR_NUMBER';
export const UPDATE_CONFIG_DIFFERENT_RESULT_LINK_LABEL = 'UPDATE_CONFIG_DIFFERENT_RESULT_LINK_LABEL';
export const UPDATE_CONFIG_RESULT_LINK_LABEL = 'UPDATE_CONFIG_RESULT_LINK_LABEL';
export const UPDATE_CONFIG_DIFFERENT_RESULT_HASH = 'UPDATE_CONFIG_DIFFERENT_RESULT_HASH';
export const UPDATE_CONFIG_LINKTO_CONTACTFORM = 'UPDATE_CONFIG_LINKTO_CONTACTFORM';
export const UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_TITLE = 'UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_TITLE';
export const UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_BACKLINK =
  'UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_BACKLINK';
export const UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_INTERNALNUMBER =
  'UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_INTERNALNUMBER';
export const UPDATE_CONFIG_LINKTO_CARSEARCH_HOME = 'UPDATE_CONFIG_LINKTO_CARSEARCH_HOME';
export const UPDATE_CONFIG_LINKTO_CARSEARCH_DETAIL = 'UPDATE_CONFIG_LINKTO_CARSEARCH_DETAIL';
export const UPDATE_CONFIG_LINKTO_CARSEARCH_RESULTS = 'UPDATE_CONFIG_LINKTO_CARSEARCH_RESULTS';
export const UPDATE_CONFIG_RANDOMIZE_LIST = 'UPDATE_CONFIG_RANDOMIZE_LIST';
export const CLEAR_QUERY = 'CLEAR_QUERY';
export const UPDATE_TOTALS = 'UPDATE_TOTALS';
export const UPDATE_PAGE_SIZE = 'UPDATE_PAGE_SIZE';
export const REFRESH_SEARCH_STATE = 'REFRESH_SEARCH_STATE';
export const UPDATE_ENABLE_ANALYTICS = 'UPDATE_ENABLE_ANALYTICS';

// viewState
export const UPDATE_MODAL_HTML_CLASS = 'UPDATE_MODAL_HTML_CLASS';
