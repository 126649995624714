import { has, get } from 'lodash-es';

/** @param {{ field: string }} element */
/** @param {string} root */
function _baseAggregationObject(element, root) {
  return {
    [root]: {
      field: element.field
    }
  };
}

/** @type {(properties: [{ [key: string]: any }]) => { [key: string]: any }} */
function mergePropertiesIntoHashMap(properties = []) {
  return properties.reduce((properties, property) => Object.assign(properties, property), {});
}

export function processSimpleAggregation(element) {
  const propertyHashMap = mergePropertiesIntoHashMap(element.properties);
  const result = {
    terms: {
      field: element.field,
      order: [{ _term: 'asc' }],
      ...propertyHashMap
    }
  };
  if (has(element, 'aggs')) {
    result.aggs = processAggregations(element.aggs);
  }
  return result;
}

export function processRangeAggregation(element) {
  const hasOwnProperty = Object.prototype.hasOwnProperty;
  const result = _baseAggregationObject(element, 'range');
  result.range.ranges = [];
  const rangesDefinition = element.properties.find(property => hasOwnProperty.call(property, 'ranges'));
  if (rangesDefinition) {
    rangesDefinition.ranges.forEach(range => {
      let rangeObject = undefined;
      if (range.startsWith('-')) {
        rangeObject = { from: range.replace('-', '') };
      } else if (range.endsWith('-')) {
        rangeObject = { to: range.replace('-', '') };
      } else {
        const splitted = range.split('-');
        rangeObject = {
          from: splitted[0],
          to: splitted[1]
        };
      }
      result.range.ranges.push(rangeObject);
    });
  }
  return result;
}

export function processDateAggregation(element) {
  const propertyHashMap = mergePropertiesIntoHashMap(element.properties);
  return {
    date_histogram: {
      field: element.field,
      format: 'E, dd. MMM Y HH:mm:ss z',
      ...propertyHashMap
    }
  };
}

export function processFilterAggregation(element) {
  const filters = get(element, 'properties', []).map(match => {
    return { match };
  });
  return {
    filters: {
      filters
    }
  };
}

/** @param {{ field: string }} element */
export function processMinMaxAggregation(element) {
  const { field } = element;
  return {
    range: {
      field,
      ranges: [
        {
          from: 0
        }
      ]
    },
    aggs: {
      max: {
        max: {
          field
        }
      },
      min: {
        min: {
          field
        }
      }
    }
  };
}

/**
 * Parses app config and transforms given JSON aggregation config to match Elasticsearch
 * syntax.
 */
export function processAggregations(aggregations) {
  return aggregations.reduce((target, aggregation) => {
    switch (aggregation.type) {
      case 'simple':
        target[aggregation.field] = processSimpleAggregation(aggregation);
        break;
      case 'range':
        target[aggregation.field] = processRangeAggregation(aggregation);
        break;
      case 'date':
        target[aggregation.field] = processDateAggregation(aggregation);
        break;
      case 'filter':
        target[aggregation.field] = processFilterAggregation(aggregation);
        break;
      case 'minmax':
        target[aggregation.field] = processMinMaxAggregation(aggregation);
        break;
      // default:
      //   console.warn(`Aggregation type '${aggregation.type}' not supported`);
    }
    return target;
  }, {});
}

/** @typedef {{ [field: string]: string }} FieldLabelHashMap */
/** @typedef {{ field: string, label: string }} FieldLabelObject */

/**
 * Parses app config and transforms given JSON aggregation config to match Elasticsearch
 * syntax.
 *
 * @param {{ aggregations: { default?: FieldLabelObject[], equipment: { single?: FieldLabelObject[], multi?: FieldLabelObject[] } } }} config
 * @return {FieldLabelHashMap}
 */
export function processLabels(config) {
  const { optionLabelMappings, aggregations } = config;
  const allAggregations = [
    ...get(aggregations, 'default', []),
    ...get(aggregations, 'equipment.single', []),
    ...get(aggregations, 'equipment.multi', [])
  ];

  return allAggregations.reduce((result, { field, label }) => {
    if (has(optionLabelMappings, field)) {
      result[field] = {
        keyLabel: label,
        optionLabels: optionLabelMappings[field]
      };
    } else {
      result[field] = label;
    }
    return result;
  }, {});
}
