import Vuex from 'vuex';
import config from './modules/config';
import newSearch from './modules/newSearch';
import viewState from './modules/viewState';
import { filter, isString, has, isUndefined, find } from 'lodash-es';

export default new Vuex.Store({
  modules: { config, newSearch, viewState },
  getters: {
    flattenConfigAggregations: state => {
      const aggregations = state.config.aggregations;
      return {
        ...aggregations.default,
        ...aggregations.equipment.single,
        ...aggregations.equipment.multi
      };
    },
    aggregationObject: (state, getters) => key => {
      const configAggs = getters.flattenConfigAggregations;
      const label = isString(state.config.labels[key]) ? state.config.labels[key] : state.config.labels[key].keyLabel;

      const optionLabels = has(state.config.labels[key], 'optionLabels') ? state.config.labels[key].optionLabels : {};
      const result = {
        ...configAggs[key],
        ...state.search.aggs[key],
        key,
        label,
        optionLabels
      };
      return result;
    },
    flattenAggregationObject: (state, getters) => key => {
      const aggregationObject = getters.aggregationObject(key);
      if (Object.prototype.hasOwnProperty.call(aggregationObject, 'buckets')) {
        return aggregationObject.buckets.map(bucket => ({
          key: aggregationObject.key,
          label: bucket.key,
          count: bucket.doc_count
        }));
      }
      return [];
    },
    selectedQueryCriteria: state => (target, field) => {
      const bucket = state.search.query[target];
      const criterias = filter(bucket, { field }) || [];
      return criterias;
    },
    labelByField: state => field => {
      return state.config.labels[field];
    },
    isWeakReference: state => field => {
      return state.config.weakReferenceTerms.indexOf(field) !== -1;
    },
    isInCurrentSearch: state => field => {
      const queries = state.search.query;
      const searchTerms = []
        .concat(queries.should)
        .concat(queries.filter)
        .concat(queries.must);
      const result = find(searchTerms, { field });
      return !isUndefined(result);
    }
  }
});
