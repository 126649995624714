import axios from 'axios';
import { processJSONConfig } from '@/model/config';
import {
  UPDATE_CONFIG,
  UPDATE_CONFIG_OFFSETX,
  UPDATE_CONFIG_OFFSETY,
  UPDATE_CONFIG_OFFSETCONTAINER,
  UPDATE_CONFIG_BASE_URL,
  UPDATE_CONFIG_SALE_SEARCH_TERM,
  UPDATE_CONFIG_SEARCH_HASH,
  UPDATE_CONFIG_MAX_CAR_NUMBER,
  UPDATE_CONFIG_RESULT_LINK_LABEL,
  UPDATE_CONFIG_DIFFERENT_RESULT_LINK_LABEL,
  UPDATE_CONFIG_DIFFERENT_RESULT_HASH,
  UPDATE_CONFIG_LOCALE,
  UPDATE_CONFIG_QUERY_KEY,
  UPDATE_CONFIG_DISABLE_HOME_SCROLL,
  UPDATE_CONFIG_LINKTO_CONTACTFORM,
  UPDATE_CONFIG_LINKTO_CARSEARCH_HOME,
  UPDATE_CONFIG_LINKTO_CARSEARCH_DETAIL,
  UPDATE_CONFIG_LINKTO_CARSEARCH_RESULTS,
  UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_TITLE,
  UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_BACKLINK,
  UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_INTERNALNUMBER,
  UPDATE_ENABLE_ANALYTICS,
  UPDATE_CONFIG_RANDOMIZE_LIST
} from '@/store/mutationTypes';

const state = {
  server: 'https://mobile-es.louis.info/',
  version: '5.5',
  index: '',
  vendor: '',
  defaultSort: '',
  vendorLocation: [],
  locationPhoneNumbers: {},
  vendorContact: [],
  makeSorting: [],
  tileElements: {
    shareButton: false
  },
  categories: [],
  offsetX: 0,
  offsetY: 0,
  offsetContainer: '',
  locale: 'de',
  baseUrl: '/',
  saleSearchTerm: '',
  searchHash: '',
  maxCarNumber: 6,
  differentResultLinkLabel: '',
  resultLinkLabel: '',
  differentResultHash: '',
  queryKey: '',
  disablehomescroll: false,
  linkToContactForm: '',
  linkToContactFormQueryParamTitle: '',
  linkToContactFormQueryParamBacklink: '',
  linkToContactFormQueryParamInternalNumber: '',
  linkToCarsearchHome: '',
  linkToCarsearchDetail: '',
  linkToCarsearchResults: '',
  offerNumber: {},
  showSearchPage: true,
  showInquiryButton: true,
  showRoundedPriceGross: false,
  showVatRateAndPriceNet: false,
  randomizeList: false,
  gtm: {}
};

const actions = {
  loadConfig({ commit }, url) {
    return axios
      .get(url)
      .then(response => {
        commit(UPDATE_CONFIG, response.data);
        return 'loaded';
      })
      .catch(console.error);
  },
  toggleAnalytics({ commit, state }, value) {
    const enabled = value === undefined ? state.gtm.enabled : value;
    commit(UPDATE_ENABLE_ANALYTICS, enabled);
    window.elasticCarsearch.toggleAnalytics(enabled);
  }
};

const mutations = {
  [UPDATE_CONFIG](state, config) {
    const processedConfig = processJSONConfig(config);
    Object.keys(processedConfig).forEach(key => {
      state[key] = processedConfig[key];
    });
  },
  [UPDATE_CONFIG_OFFSETX](state, offsetX) {
    state.offsetX = offsetX;
  },
  [UPDATE_CONFIG_OFFSETY](state, offsetY) {
    state.offsetY = offsetY;
  },
  [UPDATE_CONFIG_OFFSETCONTAINER](state, offsetContainer) {
    state.offsetContainer = offsetContainer;
  },
  [UPDATE_CONFIG_BASE_URL](state, baseUrl) {
    state.baseUrl = baseUrl;
  },
  [UPDATE_CONFIG_SALE_SEARCH_TERM](state, saleSearchTerm) {
    state.saleSearchTerm = saleSearchTerm;
  },
  [UPDATE_CONFIG_SEARCH_HASH](state, searchHash) {
    state.searchHash = searchHash;
  },
  [UPDATE_CONFIG_MAX_CAR_NUMBER](state, maxCarNumber) {
    state.maxCarNumber = maxCarNumber;
  },
  [UPDATE_CONFIG_RESULT_LINK_LABEL](state, resultLinkLabel) {
    state.resultLinkLabel = resultLinkLabel;
  },
  [UPDATE_CONFIG_DIFFERENT_RESULT_LINK_LABEL](state, differentResultLinkLabel) {
    state.differentResultLinkLabel = differentResultLinkLabel;
  },
  [UPDATE_CONFIG_DIFFERENT_RESULT_HASH](state, differentResultHash) {
    state.differentResultHash = differentResultHash;
  },
  [UPDATE_CONFIG_RANDOMIZE_LIST](state, randomizeList) {
    state.randomizeList = randomizeList;
  },
  [UPDATE_CONFIG_LOCALE](state, locale) {
    state.locale = locale;
  },
  [UPDATE_CONFIG_QUERY_KEY](state, queryKey) {
    state.queryKey = queryKey;
  },
  [UPDATE_CONFIG_DISABLE_HOME_SCROLL](state, disableHomeScroll) {
    state.disableHomeScroll = disableHomeScroll;
  },
  [UPDATE_CONFIG_LINKTO_CONTACTFORM](state, linkToContactForm) {
    state.linkToContactForm = linkToContactForm;
  },
  [UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_TITLE](state, linkToContactFormQueryParamTitle) {
    state.linkToContactFormQueryParamTitle = linkToContactFormQueryParamTitle;
  },
  [UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_BACKLINK](state, linkToContactFormQueryParamBacklink) {
    state.linkToContactFormQueryParamBacklink = linkToContactFormQueryParamBacklink;
  },
  [UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_INTERNALNUMBER](state, linkToContactFormQueryParamInternalNumber) {
    state.linkToContactFormQueryParamInternalNumber = linkToContactFormQueryParamInternalNumber;
  },
  [UPDATE_CONFIG_LINKTO_CARSEARCH_HOME](state, linkToCarsearchHome) {
    state.linkToCarsearchHome = linkToCarsearchHome;
  },
  [UPDATE_CONFIG_LINKTO_CARSEARCH_DETAIL](state, linkToCarsearchDetail) {
    state.linkToCarsearchDetail = linkToCarsearchDetail;
  },
  [UPDATE_CONFIG_LINKTO_CARSEARCH_RESULTS](state, linkToCarsearchResults) {
    state.linkToCarsearchResults = linkToCarsearchResults;
  },
  [UPDATE_ENABLE_ANALYTICS](state, enableAnalytics) {
    state.gtm.enabled = enableAnalytics;
  }
};

export default {
  state,
  actions,
  mutations
};
