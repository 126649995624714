import Vue from 'vue';
import VueI18n from 'vue-i18n';
import de from '@/locales/de';

Vue.use(VueI18n);

const dateTimeFormats = {
  de: {
    short: {
      year: 'numeric',
      month: '2-digit'
    }
  }
};

const numberFormats = {
  de: {
    currency: {
      style: 'currency',
      currency: 'EUR',
      currencyDisplay: 'symbol'
    },
    fractionlessCurrency: {
      style: 'currency',
      currency: 'EUR',
      maximumFractionDigits: 0,
      minimumFractionDigits: 0
    },
    twoDigitDecimal: {
      maximumFractionDigits: 2,
      useGrouping: false
    }
  }
};

// i18n implementation is intentionally minimal
// extend once needed
const i18n = new VueI18n({
  locale: 'de',
  messages: { de },
  dateTimeFormats,
  numberFormats
});

export default i18n;
