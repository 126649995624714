export const BASE_URL = `${process.env.BASE_URL || ''}`;

export const CO2_EFFICIENCY_CLASSES = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
export const CO2_EFFICIENCY_CLASS_SUFFIX_MAP = {
  [CO2_EFFICIENCY_CLASSES[0]]: 'a',
  [CO2_EFFICIENCY_CLASSES[1]]: 'b',
  [CO2_EFFICIENCY_CLASSES[2]]: 'c',
  [CO2_EFFICIENCY_CLASSES[3]]: 'd',
  [CO2_EFFICIENCY_CLASSES[4]]: 'e',
  [CO2_EFFICIENCY_CLASSES[5]]: 'f',
  [CO2_EFFICIENCY_CLASSES[6]]: 'g'
};

export const ENERGY_EFFICIENCY_CLASSES = ['A+++', 'A++', 'A+', 'A', 'B', 'C', 'D', 'E', 'F', 'G'];
export const ENERGY_EFFICIENCY_CLASS_SUFFIX_MAP = {
  [ENERGY_EFFICIENCY_CLASSES[0]]: 'a-plus-p-p',
  [ENERGY_EFFICIENCY_CLASSES[1]]: 'a-plus-p',
  [ENERGY_EFFICIENCY_CLASSES[2]]: 'a-plus',
  [ENERGY_EFFICIENCY_CLASSES[3]]: 'a',
  [ENERGY_EFFICIENCY_CLASSES[4]]: 'b',
  [ENERGY_EFFICIENCY_CLASSES[5]]: 'c',
  [ENERGY_EFFICIENCY_CLASSES[6]]: 'd',
  [ENERGY_EFFICIENCY_CLASSES[7]]: 'e',
  [ENERGY_EFFICIENCY_CLASSES[8]]: 'f',
  [ENERGY_EFFICIENCY_CLASSES[9]]: 'g'
};

export const BUTTON_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  GRAY: 'gray'
};

export const SORTBY_DEFAULT = 'make:asc,model:asc';
export const SORTBY_CREATEDAT_DESC = 'created_at:desc';
export const ROOT_CONTAINER_ID = 'lia-carsearch';
export const CAR_AGE_TYPE_NEW = 'Neufahrzeug';
export const CAR_AGE_TYPE_USED = 'Gebrauchtfahrzeug';
