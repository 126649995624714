import { has, get, toNumber } from 'lodash-es';
import { processLabels, processAggregations } from './configProcessor';
import {
  UPDATE_CONFIG_OFFSETX,
  UPDATE_CONFIG_OFFSETY,
  UPDATE_CONFIG_OFFSETCONTAINER,
  UPDATE_CONFIG_BASE_URL,
  UPDATE_CONFIG_SEARCH_HASH,
  UPDATE_CONFIG_MAX_CAR_NUMBER,
  UPDATE_CONFIG_DIFFERENT_RESULT_LINK_LABEL,
  UPDATE_CONFIG_RESULT_LINK_LABEL,
  UPDATE_CONFIG_DIFFERENT_RESULT_HASH,
  UPDATE_CONFIG_LOCALE,
  UPDATE_CONFIG_LINKTO_CONTACTFORM,
  UPDATE_CONFIG_QUERY_KEY,
  UPDATE_CONFIG_DISABLE_HOME_SCROLL,
  UPDATE_CONFIG_LINKTO_CARSEARCH_HOME,
  UPDATE_CONFIG_LINKTO_CARSEARCH_DETAIL,
  UPDATE_CONFIG_LINKTO_CARSEARCH_RESULTS,
  UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_TITLE,
  UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_BACKLINK,
  UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_INTERNALNUMBER,
  UPDATE_CONFIG_SALE_SEARCH_TERM,
  UPDATE_CONFIG_RANDOMIZE_LIST
} from '@/store/mutationTypes';

function loadTenantCSS(tenantId) {
  return import(`@/assets/scss/${tenantId}.scss.tenant`).catch(console.error);
}

/** @type {(path: string) => string} */
function extractTenantIdFromPath(path) {
  const parts = path.split('/');
  const lastPart = parts[parts.length - 1];
  const [tenantId] = lastPart.split('.');
  return tenantId;
}

const alreadyLoadedTenants = [];
export async function loadConfigByElement(store, element) {
  // load search config on start up
  const externalConfig = element.dataset.config;
  if (externalConfig && !alreadyLoadedTenants.includes(externalConfig)) {
    alreadyLoadedTenants.push(externalConfig);
    const tenantId = extractTenantIdFromPath(externalConfig);
    await Promise.all([store.dispatch('loadConfig', externalConfig), loadTenantCSS(tenantId)]);
  }
  const enableAnalytics = element.dataset.enableanalytics;
  if (enableAnalytics) {
    store.dispatch('toggleAnalytics', enableAnalytics.toLowerCase() === 'false');
  }
  const offsetX = element.dataset.offsetx;
  if (offsetX) {
    store.commit(UPDATE_CONFIG_OFFSETX, toNumber(offsetX));
  }
  const offsetY = element.dataset.offsety;
  if (offsetY) {
    store.commit(UPDATE_CONFIG_OFFSETY, toNumber(offsetY));
  }
  const offsetContainer = element.dataset.offsetcontainer;
  if (offsetContainer) {
    store.commit(UPDATE_CONFIG_OFFSETCONTAINER, offsetContainer);
  }
  const locale = element.dataset.locale;
  if (locale) {
    store.commit(UPDATE_CONFIG_LOCALE, locale);
  }
  const baseUrl = element.dataset.baseurl;
  if (baseUrl) {
    store.commit(UPDATE_CONFIG_BASE_URL, baseUrl);
  }
  const saleSearchTerm = element.dataset.salesearchterm;
  if (baseUrl) {
    store.commit(UPDATE_CONFIG_SALE_SEARCH_TERM, saleSearchTerm);
  }
  const searchHash = element.dataset.searchhash;
  if (searchHash) {
    store.commit(UPDATE_CONFIG_SEARCH_HASH, searchHash);
  }
  const maxCarNumber = element.dataset.maxcarnumber;
  if (maxCarNumber) {
    store.commit(UPDATE_CONFIG_MAX_CAR_NUMBER, toNumber(maxCarNumber));
  }
  const differentResultLinkLabel = element.dataset.differentresultlinklabel;
  if (differentResultLinkLabel) {
    store.commit(UPDATE_CONFIG_DIFFERENT_RESULT_LINK_LABEL, differentResultLinkLabel);
  }
  const resultLinkLabel = element.dataset.resultlinklabel;
  if (resultLinkLabel) {
    store.commit(UPDATE_CONFIG_RESULT_LINK_LABEL, resultLinkLabel);
  }
  const differentResultHash = element.dataset.differentresulthash;
  if (differentResultHash) {
    store.commit(UPDATE_CONFIG_DIFFERENT_RESULT_HASH, differentResultHash);
  }
  const randomizeList = element.dataset.randomizelist;
  if (randomizeList) {
    store.commit(UPDATE_CONFIG_RANDOMIZE_LIST, randomizeList === 'true');
  }
  const linkToContactForm = element.dataset.linktocontactform;
  if (linkToContactForm) {
    store.commit(UPDATE_CONFIG_LINKTO_CONTACTFORM, linkToContactForm);
  }
  const linkToContactFormQueryParamTitle = element.dataset.linktocontactformqueryparamtitle;
  if (linkToContactFormQueryParamTitle) {
    store.commit(UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_TITLE, linkToContactFormQueryParamTitle);
  }
  const linkToContactFormQueryParamBacklink = element.dataset.linktocontactformqueryparambacklink;
  if (linkToContactFormQueryParamBacklink) {
    store.commit(UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_BACKLINK, linkToContactFormQueryParamBacklink);
  }
  const linkToContactFormQueryParamInternalNumber = element.dataset.linktocontactformqueryparaminternalnumber;
  if (linkToContactFormQueryParamInternalNumber) {
    store.commit(UPDATE_CONFIG_LINKTO_CONTACTFORM_QUERYPARAM_INTERNALNUMBER, linkToContactFormQueryParamInternalNumber);
  }
  const linkToCarsearchHome = element.dataset.linktocarsearchhome;
  if (linkToCarsearchHome) {
    store.commit(UPDATE_CONFIG_LINKTO_CARSEARCH_HOME, linkToCarsearchHome);
  }
  const linkToCarsearchDetail = element.dataset.linktocarsearchdetail;
  if (linkToCarsearchDetail) {
    store.commit(UPDATE_CONFIG_LINKTO_CARSEARCH_DETAIL, linkToCarsearchDetail);
  }
  const linkToCarsearchResults = element.dataset.linktocarsearchresults;
  if (linkToCarsearchResults) {
    store.commit(UPDATE_CONFIG_LINKTO_CARSEARCH_RESULTS, linkToCarsearchResults);
  }
  const queryKey = element.dataset.querykey;
  if (queryKey) {
    store.commit(UPDATE_CONFIG_QUERY_KEY, queryKey);
  }
  const disableHomeScroll = element.dataset.disablehomescroll;
  if (disableHomeScroll) {
    store.commit(UPDATE_CONFIG_DISABLE_HOME_SCROLL, 'true' == disableHomeScroll);
  }
}

export function processJSONConfig(jsonConfig) {
  let result = {
    server: jsonConfig.server,
    version: jsonConfig.version,
    index: jsonConfig.index,
    defaultSort: jsonConfig.defaultSort,
    vendor: jsonConfig.vendor,
    offerNumber: {
      hide: false,
      mapToField: 'interne_nummer',
      placeholderText: ''
    },
    aggregations: {
      default: processAggregations(get(jsonConfig, 'aggregations.default', [])),
      equipment: {
        single: processAggregations(get(jsonConfig, 'aggregations.equipment.single', [])),
        multi: processAggregations(get(jsonConfig, 'aggregations.equipment.multi', []))
      }
    },
    categories: get(jsonConfig, 'categories', []),
    labels: processLabels(jsonConfig)
  };
  if (has(jsonConfig, 'weakReferenceTerms')) {
    result.weakReferenceTerms = jsonConfig.weakReferenceTerms;
  }
  if (has(jsonConfig, 'vendorLocation')) {
    result.vendorLocation = jsonConfig.vendorLocation;
  }
  if (has(jsonConfig, 'locationPhoneNumbers')) {
    result.locationPhoneNumbers = jsonConfig.locationPhoneNumbers;
  }
  if (has(jsonConfig, 'makeSorting')) {
    result.makeSorting = jsonConfig.makeSorting;
  }
  if (has(jsonConfig, 'newCarWidgetNumber')) {
    result.newCarWidgetNumber = jsonConfig.newCarWidgetNumber;
  }
  if (has(jsonConfig, 'whatsappLink')) {
    result.whatsappLink = jsonConfig.whatsappLink;
  }
  if (has(jsonConfig, 'showLeasingPrice')) {
    result.showLeasingPrice = jsonConfig.showLeasingPrice;
  }
  if (has(jsonConfig, 'showMotorHomes')) {
    result.showMotorHomes = jsonConfig.showMotorHomes;
  }
  if (has(jsonConfig, 'bundleCarTypes')) {
    result.bundleCarTypes = jsonConfig.bundleCarTypes;
  }
  if (has(jsonConfig, 'leasingFootnote')) {
    result.leasingFootnote = jsonConfig.leasingFootnote;
  }
  if (has(jsonConfig, 'financingFootnote')) {
    result.financingFootnote = jsonConfig.financingFootnote;
  }
  if (has(jsonConfig, 'tileElements')) {
    result.tileElements = jsonConfig.tileElements;
  }
  if (has(jsonConfig, 'vendorContact')) {
    // shape: {"locationCode": "U15", "contactImage": "http://lorempixel.com/g/180/180/people/9", "contactName": "Max Mustermann", "contactPosition": "AZ", "phoneNumber": "+4912345678", "mail": "info@louis.com"}
    result.vendorContact = jsonConfig.vendorContact;
  }
  if (has(jsonConfig, 'offerNumber')) {
    result.offerNumber = { ...result.offerNumber, ...jsonConfig.offerNumber };
  }
  if (has(jsonConfig, 'showRoundedPriceGross')) {
    result.showRoundedPriceGross = jsonConfig.showRoundedPriceGross;
  }
  if (has(jsonConfig, 'showSearchPage')) {
    result.showSearchPage = jsonConfig.showSearchPage;
  }
  if (has(jsonConfig, 'showInquiryButton')) {
    result.showInquiryButton = jsonConfig.showInquiryButton;
  }
  if (has(jsonConfig, 'showVatRateAndPriceNet')) {
    result.showVatRateAndPriceNet = jsonConfig.showVatRateAndPriceNet;
  }
  if (has(jsonConfig, 'gtm')) {
    result.gtm = jsonConfig.gtm;
  }

  return result;
}
