import store from '@/store';
import i18n from '@/i18n';

/** @param {number} price */
export function formatPrice(price) {
  if (store.state.config.showRoundedPriceGross) {
    if (!price) {
      return i18n.n(0, 'fractionlessCurrency');
    }

    return i18n.n(Math.round(price), 'fractionlessCurrency');
  }

  if (!price) return i18n.n(0, 'currency');

  return i18n.n(price, 'currency');
}
