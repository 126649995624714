import LZString from 'lz-string';
import axios from 'axios';
import { createFormData } from '@/util';

let previousGetAggregationAbortController = null;
let previousGetSearchresultsAbortController = null;

/**
 * @typedef CarDataImage
 * @property {string} image
 * @property {string} large
 * @property {string} small
 * @property {string} thumb
 */
/**
 * @typedef CarDataContact
 * @property {string} company
 * @property {string} salutation
 * @property {string} firstname
 * @property {string} lastname
 * @property {string} street
 * @property {string} zip
 * @property {string} city
 * @property {string} phone
 * @property {string} fax
 * @property {string} email
 */
/**
 * PKW Daten
 *
 * @typedef CarDataSSCD
 * @property {Array<CarDataImage>} images
 * @property {CarDataContact} contact
 * @property {string} name
 * @property {string} fahrzeugart
 * @property {number} leistung_ps
 * @property {string} basecolor
 * @property {string} bruttopreis
 * @property {boolean} nutzfahrzeug
 * @property {string} ecs_benutzer_eigenschaften
 * @property {string} make
 * @property {string} type
 * @property {string} category
 * @property {string} vendor
 * @property {string} make_short
 * @property {string} type_short
 * @property {string} category_short
 * @property {string} vendor_short
 * @property {number} id
 * @property {string} interne_nummer
 * @property {number} leistung
 * @property {string} preis
 * @property {boolean} oldtimer
 * @property {boolean} beschaedigtes_fahrzeug
 * @property {boolean} taxi
 * @property {boolean} behindertengerecht
 * @property {boolean} unsere_empfehlung
 * @property {boolean} metallic
 * @property {boolean} garantie
 * @property {boolean} leichtmetallfelgen
 * @property {boolean} esp
 * @property {boolean} abs
 * @property {boolean} anhaengerkupplung
 * @property {boolean} wegfahrsperre
 * @property {boolean} navigationssystem
 * @property {boolean} schiebedach
 * @property {boolean} zentralverriegelung
 * @property {boolean} fensterheber
 * @property {boolean} allradantrieb
 * @property {boolean} servolenkung
 * @property {boolean} biodiesel
 * @property {boolean} scheckheftgepflegt
 * @property {boolean} katalysator
 * @property {boolean} kickstarter
 * @property {boolean} estarter
 * @property {boolean} tempomat
 * @property {boolean} standheizung
 * @property {boolean} tv
 * @property {boolean} wc
 * @property {boolean} schiebetuer
 * @property {boolean} sichtbar_nur_fuer_haendler
 * @property {boolean} reserviert
 * @property {boolean} envkv
 * @property {boolean} xenonscheinwerfer
 * @property {boolean} sitzheizung
 * @property {boolean} partikelfilter
 * @property {boolean} einparkhilfe
 * @property {boolean} exportfahrzeug
 * @property {boolean} blickfaenger
 * @property {boolean} seite_1_inserat
 * @property {boolean} pflanzenoel
 * @property {boolean} koffer
 * @property {boolean} sturzbuegel
 * @property {boolean} scheibe
 * @property {boolean} standklima
 * @property {boolean} s_s_bereifung
 * @property {boolean} strassenzulassung
 * @property {boolean} etagenbett
 * @property {boolean} festbett
 * @property {boolean} heckgarage
 * @property {boolean} markise
 * @property {boolean} sep_dusche
 * @property {boolean} solaranlage
 * @property {boolean} mittelsitzgruppe
 * @property {boolean} rundsitzgruppe
 * @property {boolean} seitensitzgruppe
 * @property {boolean} hagelschaden
 * @property {boolean} anzeige
 * @property {boolean} finanzierungsfeature
 * @property {boolean} elektrische_seitenspiegel
 * @property {boolean} sportfahrwerk
 * @property {boolean} sportpaket
 * @property {boolean} bluetooth
 * @property {boolean} bordcomputer
 * @property {boolean} cd_spieler
 * @property {boolean} elektrische_sitzeinstellung
 * @property {boolean} head_up_display
 * @property {boolean} freisprecheinrichtung
 * @property {boolean} mp3_schnittstelle
 * @property {boolean} multifunktionslenkrad
 * @property {boolean} skisack
 * @property {boolean} tuner_oder_radio
 * @property {boolean} sportsitze
 * @property {boolean} panorama_dach
 * @property {boolean} kindersitzbefestigung
 * @property {boolean} kurvenlicht
 * @property {boolean} lichtsensor
 * @property {boolean} nebelscheinwerfer
 * @property {boolean} tagfahrlicht
 * @property {boolean} traktionskontrolle
 * @property {boolean} start_stop_automatik
 * @property {boolean} regensensor
 * @property {boolean} nichtraucher_fahrzeug
 * @property {boolean} dachreling
 * @property {boolean} e10
 * @property {boolean} scr
 * @property {boolean} top_inserat
 * @property {boolean} rotstiftpreis
 * @property {boolean} ebayk_export
 * @property {boolean} plugin_hybrid
 * @property {string} farbe
 * @property {string} haendlerpreis
 * @property {string} bemerkung
 * @property {string} waehrung
 * @property {string} mwstsatz
 * @property {string} lieferdatum
 * @property {string} hsn
 * @property {string} tsn
 * @property {string} anbieterbank
 * @property {string} landesversion
 * @property {string} video_url
 * @property {string} created_at
 * @property {string} updated_at
 * @property {string} schadstoff
 * @property {string} mwst
 * @property {string} klima
 * @property {string} umweltplakette
 * @property {string} kraftstoffart
 * @property {string} getriebeart
 * @property {string} interieurfarbe
 * @property {string} interieurtyp
 * @property {string} verbrauch_einheit
 * @property {string} custom1
 * @property {string} custom2
 * @property {number} tueren
 * @property {number} ccm
 * @property {number} sitze
 * @property {number} leasing_zielgruppe
 * @property {boolean} einparkhilfe_sensoren_vorne
 * @property {boolean} einparkhilfe_sensoren_hinten
 * @property {boolean} einparkhilfe_kamera
 * @property {boolean} einparkhilfe_selbstlenkendes_system
 * @property {string} produkt
 * @property {string} wartungbeschreibung
 * @property {string} garantiebeschreibung
 * @property {string} kfz_versicherung_beschreibung
 * @property {number} [kilometer]
 * @property {string} [model]
 * @property {string} [model_short]
 * @property {string} [hu]
 * @property {string} [au]
 * @property {string} [ez]
 * @property {string} [ueberführungskosten]
 * @property {string} [kaufpreis]
 * @property {string} [ueberfuehrungskosten]
 * @property {string} [garantiekosten]
 * @property {string} [emission]
 * @property {boolean} [lederausstattung]
 * @property {number} [baujahr]
 * @property {number} [verbrauch_innerorts]
 * @property {number} [verbrauch_ausserorts]
 * @property {number} [verbrauch_kombiniert]
 * @property {boolean} [beschaedigung]
 * @property {number} [schwackecode]
 * @property {boolean} [hu_au_neu]
 * @property {number} [schlafplaetze]
 * @property {number} [fahrzeuglaenge]
 * @property {number} [fahrzeugbreite]
 * @property {number} [fahrzeughoehe]
 * @property {string} [emission]
 * @property {string} [effektiver_jahreszins]
 * @property {string} [monatliche_rate]
 * @property {string} [anzahlung]
 * @property {string} [schlussrate]
 * @property {string} [laufleistung]
 * @property {string} [vorbesitzer]
 * @property {string} [bruttokreditbetrag]
 * @property {string} [abschlussgebuehren]
 * @property {string} [ratenabsicherung]
 * @property {string} [nettokreditbetrag]
 * @property {string} [soll_zinssatz]
 * @property {string} [tenant_id]
 * @property {string} [leasing_monatliche_rate]
 * @property {string} [leasing_anzahlung]
 * @property {string} [leasing_laufleistung]
 * @property {string} [leasingfeature]
 * @property {string} [listenpreis]
 * @property {string} [produktionsdatum]
 * @property {string} [top_inserat_mobile]
 * @property {string} [laufzeit]
 * @property {string} [ecs_finanzierung_km_laufleistung_jahr]
 * @property {string} [antrieb]
 * @property {string} [lieferfrist]
 * @property {string} [qualitaetssiegel]
 * @property {string} [airbag]
 * @property {string} [art_des_soll_zinssatzes]
 * @property {string} [energieeffizienzklasse]
 * @property {string} [envkv_benzin_sorte]
 * @property {string} [leasing_effektiver_jahreszins]
 * @property {string} [leasing_laufzeit]
 * @property {string} [leasing_laufleistung_km]
 * @property {string} [leasing_restwert]
 * @property {string} [leasing_mehrkilometer_in_euro_cent]
 * @property {string} [leasing_minderkilometer_in_euro_cent]
 * @property {string} [leasing_anbieterbank]
 * @property {string} [leasing_sollzins]
 * @property {string} [leasing_zinsart]
 * @property {string} [leasing_gesamtbetrag]
 * @property {string} [leasing_netto_kredit_betrag]
 * @property {string} [wartung_pro_monat]
 * @property {string} [vollkasko_pro_monat]
 * @property {string} [lieferung_einmalig]
 * @property {string} [zulassungskosten_einmalig]
 * @property {string} [restwert_abschlag_in_prozent]
 * @property {string} [leasingfaktor]
 */
/**
 * Zusätzliche Nutzfahrzeugdaten
 *
 * @typedef CarDataNSCD
 * @property {number} gesamtgewicht
 * @property {boolean} kabine
 * @property {boolean} schutzdach
 * @property {boolean} vollverkleidung
 * @property {boolean} komunal
 * @property {boolean} kran
 * @property {boolean} retarder_intarder
 * @property {boolean} schlafplatz
 * @property {boolean} ladebordwand
 * @property {boolean} trennwand
 * @property {boolean} ebs
 * @property {boolean} vermietbar
 * @property {boolean} kompressor
 * @property {boolean} luftfederung
 * @property {boolean} scheibenbremse
 * @property {boolean} fronthydraulik
 * @property {boolean} bss
 * @property {boolean} schnellwechsel
 * @property {boolean} zsa
 * @property {boolean} kueche
 * @property {boolean} kuehlbox
 * @property {boolean} schlafsitze
 * @property {boolean} frontheber
 * @property {string} [kabinenart]
 * @property {number} [tragkraft]
 * @property {number} [nutzlast]
 * @property {number} [hubhoehe]
 * @property {number} [bauhoehe]
 * @property {number} [betriebsstunden]
 * @property {number} [achsen]
 * @property {number} [laderaum_europalette]
 * @property {number} [laderaum_volumen]
 * @property {number} [laderaum_laenge]
 * @property {number} [laderaum_breite]
 * @property {number} [laderaum_hoehe]
 * @property {string} [hydraulikanlage]
 * @property {string} [radformel]
 */
/**
 * @typedef CarDataSSCDEnvkvExtension
 * @property {string} [wltp_co2_klasse]
 * @property {string} [wltp_co2_klasse_gewichtet_kombiniert]
 * @property {string} [wltp_co2_klasse_entladene_batterie]
 * @property {string} [wltp_co2_emission]
 * @property {string} [wltp_kombiniert]
 * @property {string} [wltp_kombiniert_stromverbrauch]
 * @property {string} [wltp_verbrauch_innenstadt]
 * @property {string} [wltp_verbrauch_stadtrand]
 * @property {string} [wltp_verbrauch_landstrasse]
 * @property {string} [wltp_verbrauch_autobahn]
 * @property {string} [wltp_co2_emission_gewichtet_kombiniert]
 * @property {string} [wltp_gewichtet_kombiniert_stromverbrauch]
 * @property {string} [wltp_gewichtet_kombiniert_kraftstoffverbrauch]
 * @property {string} [wltp_co2_emission_entladene_batterie]
 * @property {string} [wltp_kombiniert_kraftstoffverbrauch_entladene_batterie]
 * @property {string} [wltp_stromverbrauch_innenstadt]
 * @property {string} [wltp_stromverbrauch_stadtrand]
 * @property {string} [wltp_stromverbrauch_landstrasse]
 * @property {string} [wltp_stromverbrauch_autobahn]
 * @property {string} [elektromotor_reichweite]
 * @property {string} [elektromotor_reichweite_innerorts]
 * @property {string} [elektromotor_reichweite_plugin_hybrid]
 * @property {string} [elektromotor_reichweite_plugin_hybrid_innerorts]
 */
/** @typedef {CarDataSSCD & CarDataNSCD & CarDataSSCDEnvkvExtension} CarData */

export default {
  $vue: null,
  $store: null,
  decode(hash) {
    const decompressed = LZString.decompressFromEncodedURIComponent(hash);
    const destringified = JSON.parse(decompressed);

    return destringified;
  },
  encode(data) {
    const stringified = JSON.stringify(data);
    const hash = LZString.compressToEncodedURIComponent(stringified);

    return hash;
  },
  getAggregation() {
    if (previousGetAggregationAbortController) previousGetAggregationAbortController.cancel();
    previousGetAggregationAbortController = axios.CancelToken.source();

    return axios.post(
      `${this.$store.state.config.server}${this.$store.state.config.index}/_search?size=0`,
      this.$store.state.newSearch.staticAggs,
      { cancelToken: previousGetAggregationAbortController.token }
    );
  },
  /** @type {() => Promise<import('axios').AxiosResponse<{ hits: { hits: Array<CarData> } }>>} */
  async getSearchresults() {
    if (previousGetSearchresultsAbortController) previousGetSearchresultsAbortController.cancel();
    previousGetSearchresultsAbortController = axios.CancelToken.source();

    const queryObject = await this.$store.dispatch('fillQuery');
    return axios.post(
      `${this.$store.state.config.server}${this.$store.state.config.index}/_search?size=0`,
      {
        aggs: this.$store.getters.aggs.aggs,
        query: queryObject.query
      },
      { cancelToken: previousGetSearchresultsAbortController.token }
    );
  },
  fillQuery(hash) {
    const queryObject = this.decode(hash);

    const formData = createFormData({ $store: this.$store, context: this.$vue });
    [
      'ausstattung',
      'basecolor',
      'metallic',
      'fahrzeugart',
      'fahrzeugtyp',
      'custom1',
      'custom2',
      'getriebeart',
      'interieurfarbe',
      'interieurtyp',
      'klima',
      'kraftstoffart',
      'make',
      'model',
      'mwst',
      'sitze',
      'tueren',
      'sale',
      'kilometer',
      'ez',
      'modelVariant',
      'bruttopreis',
      'leistung_ps'
    ].forEach(fieldName => {
      if (queryObject[fieldName]) formData[fieldName] = queryObject[fieldName];
    });

    formData.save();
  },
  async getCountByHash(hash) {
    this.$store.dispatch('reset');
    this.fillQuery(hash);
    const response = await this.getSearchresults();

    return response.data.hits.total;
  }
};
